import React from 'react';
import Col from 'react-bootstrap/Col';

import '../../css/input.css'


export default class InputField extends React.Component {

	handleChange=e=>{
		this.props.onChange(e.target.value);
	}

	render() {

		let { 
			readOnly, isMandatory, validationFailed, label, subLabel, labelClass,
			value, inputClass, containerClass, mutlipleLine, style, inputType,
			onBlur, onKeyPress, maxLength, max } = this.props;
		
		containerClass = (containerClass?containerClass:"") + (validationFailed?" validationFailed":"");

		labelClass = "inputLabel boldFont " + (labelClass?labelClass:"");
		labelClass = labelClass + (isMandatory?" mandatoryInput":"") + (validationFailed?" validationFailed":"");
		
		inputClass = "normalInput normalFont form-control " + (inputClass?inputClass:"");

		style = style ? style : {}

		inputType = inputType ? inputType : "input";
		
	    return (
			<Col className={"inputContainer " + containerClass}>
		    	<p className={labelClass}>{label}</p>
		    	{subLabel ? <p className="smallFont">{subLabel}</p> : null}
	    		{
	    			readOnly?
		    			mutlipleLine ? 
					  		<textarea readOnly style={style} maxLength={maxLength} className={inputClass + " inputTextArea"} value={value} onChange={this.handleChange} onBlur={onBlur} onKeyPress={onKeyPress}/>
					  		:
					  		<input type = {inputType} readOnly style={style} maxLength={maxLength} max={max} className={inputClass} value={value} onChange={this.handleChange} onBlur={onBlur} onKeyPress={onKeyPress}/>
						:
						mutlipleLine ? 
					  		<textarea style={style} className={inputClass + " inputTextArea"} maxLength={maxLength} value={value} onChange={this.handleChange} onBlur={onBlur} onKeyPress={onKeyPress}/>
					  		:
					  		<input type = {inputType} style={style} className={inputClass} maxLength={maxLength} max={max} value={value} onChange={this.handleChange} onBlur={onBlur} onKeyPress={onKeyPress}/>
				
				}	
		  	</Col>
	  	);
		
	}
}
