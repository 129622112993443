import { 
	SET_TYPE, CLEAR_SEARCH,
	RETRIEVE_INFO, RETRIEVE_INFO_SUCCESS, RETRIEVE_INFO_ERROR,
	SEARCH_KEYWORD, SEARCH_KEYWORD_SUCCESS, SEARCH_KEYWORD_ERROR,
	SEARCH_MORE, SEARCH_MORE_SUCCESS, SEARCH_MORE_ERROR,
	CHANGE_PAGE, CHANGE_PAGE_SUCCESS, CHANGE_PAGE_ERROR,
	} from "../actions/actionTypes";

import Utility from '../utility.js';

const initialState = { 
	isFetching:false,
	errMsg:[],

	title:"",
	linkTo:"",
	refType:"", // SI or CRT	
	totalPages:1,
	page:1,
	pdfData:null,
	searchKey:"",
	searchCount:Utility.searchCount,
	wholeWords:false,
	caseSensitive:false,
	currentSearchIndex:0,
	ttlNumOfResult:0,
	searchResultList:[],
	districtsList:[],
	pageIndices:{},
};

const CONFIG = window.CONFIG;

const parseInfo = data => {
	let info={};
	info.totalPages = data.totalPages;
	info.pdfData = Utility.b64toBlob(data.firstPagePDFBase64);
	info.districtsList = Utility.mergeSort(data.districtsList, "code")
	
	info.pageIndices = {};

	for(let i = 0; i < data.pageIndices.length; i++) {
		let district = data.pageIndices[i].district;
		
		if (info.pageIndices[district] === undefined) {
			info.pageIndices[district] = [];
		}

		info.pageIndices[district].push(data.pageIndices[i]);
	}

	for (var key in info.pageIndices) {
		info.pageIndices[key] = Utility.mergeSort(info.pageIndices[key], "prefix");
	}
	return info;

}

const streetIndex = (state=initialState, action) => {
	switch (action.type) {
		case CHANGE_PAGE:

			return {...state, isFetching:true, errMsg:[]};

		case CHANGE_PAGE_SUCCESS:
			let pdfData = Utility.b64toBlob(action.payload.pdfData)
			return {...state, isFetching:false, page:action.payload.page, pdfData:pdfData};

		case CHANGE_PAGE_ERROR:
			return {...state, isFetching:false, errMsg:[action.payload.message]};

		case SEARCH_KEYWORD:
			return {...state, searchKey:"", isFetching:true, errMsg:[]};

		case SEARCH_KEYWORD_SUCCESS:
		{
			let {
				searchKey, wholeWords,
				caseSensitive, searchResultList,
				searchCount, ttlNumOfResult } = action.payload;
			let currentSearchIndex = parseInt(searchCount) + 1;
			return {
				...state,
				isFetching:false,
				searchKey:searchKey,
				searchCount:searchCount,
				wholeWords:wholeWords,
				caseSensitive:caseSensitive,
				ttlNumOfResult:ttlNumOfResult,
				searchResultList:searchResultList.sort((a,b) => a.masterPageNum - b.masterPageNum),
				currentSearchIndex:currentSearchIndex};
		}
		case SEARCH_KEYWORD_ERROR:
			return {...state, isFetching:false, errMsg:[action.payload.message]};

		case SEARCH_MORE:
			return {...state, isFetching:true, errMsg:[]};

		case SEARCH_MORE_SUCCESS:
		{
			let currentSearchIndex = parseInt(state.currentSearchIndex) + parseInt(state.searchCount);

			// state.searchResultList.pop(); // remove the duplicated element
			let searchResultList = [...state.searchResultList, ...action.payload.searchResultList]

			return {
				...state,
				isFetching:false,
				searchResultList:searchResultList,
				currentSearchIndex:currentSearchIndex};
		}
		case SEARCH_MORE_ERROR:
			return {...state, isFetching:false, errMsg:[action.payload.message]};

		case RETRIEVE_INFO:
			return {...state, isFetching:true, errMsg:[]};

		case RETRIEVE_INFO_SUCCESS:
			let info = parseInfo(action.payload);

			return {...state,
				isFetching:false,
				totalPages:info.totalPages,
				pdfData:info.pdfData,
				districtsList:info.districtsList,
				pageIndices:info.pageIndices,
			};

		case RETRIEVE_INFO_ERROR:
			return {...state, isFetching:false, errMsg:[action.payload.message]};

		case CLEAR_SEARCH:
			return {...state, searchKey:"", searchResultList:[], currentSearchIndex:0, ttlNumOfResult:0};

		case SET_TYPE:
			let refType = action.payload;
			let title = "";
			let linkTo = "";

			switch(refType) {
			case "CRT":
				title = CONFIG.CRTR;
				linkTo = CONFIG.CRTV;
				break;
			case "SI":
				title = CONFIG.SITR;
				linkTo = CONFIG.SITV;
				break;
			default:
				break;
			}
			return {...state, refType:action.payload, title:title, linkTo:linkTo}
		default:
			return state;
	}
 	
};

export default streetIndex;
