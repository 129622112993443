import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./store";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import StreetIndex from './layout/streetIndex.js';
import './css/index.css';
import './css/spinner.css';


window.onPerformRecaptchaCallback = () => { 
	window.performRecaptchaCompleted(); 
};

ReactDOM.render(
	<Provider store={store}>
    <StreetIndex />
  	</Provider>
  	,
  	document.getElementById('root')
);

axios.defaults.headers.common['Content-Type'] = 'application/json';
