import React from 'react';
import { connect } from "react-redux";
import { 
	changePage, searchKeyword,
	clearSearch, retrieveInfo,
	setType, searchMore, searchKeywordError,
	} from "../actions/actions";
// import { BrowserRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { IconContext } from "react-icons";
import { FaSearch,FaCaretLeft,FaCaretRight, FaCaretDown, FaSearchPlus, FaSearchMinus } from "react-icons/fa";

import logo from '../resources/lr_branding.png';
import InputField from './component/inputField.js';
import Checkbox from './component/checkbox.js';
import GeneralModal from './component/Modal/generalModal.js';
import PDFContent from './pdfContent.js';

import Utility from '../utility.js';

import '../css/streetIndex.css'

const CONFIG = window.CONFIG;

class StreetIndex extends React.Component {
	
	constructor(props) {
		super(props);
		this.state={
			pdfScale:1,
			searchKey:"",
			searchCount:Utility.searchCount,
			searchList:null,
			isHighlighted:false,
			highlightingResult:null,
			numPages:0,
			currentPage:1,
			pdfFile:null,
			districtTabs:null,
			wholeWords:false,
			caseSensitive:false,
			hasError:false,
			orderedDistrictList:[],
			hasInfo:false,
			infoMsg:[],
		}
	}

	debug = false;
	log = s => { if(this.debug) console.log(s); }

	handleKeyPress=e=>{
		if(e.key ==="Enter") {
			this.search();
		}
	}

	linkToOtherTable=()=>{
		switch (this.props.streetIndex.refType) {
		case "CRT":
			window.open(CONFIG.WEBSITE_SI_URL, '_self')
			break;
		case "SI":
		default:
			window.open(CONFIG.WEBSITE_CRT_URL, '_self')
			break;
		}
	}

	zoomIn=()=>{
		let pdfScale = this.state.pdfScale;

		pdfScale = Math.max(pdfScale-0.1, 0.5);

		this.setState({pdfScale:pdfScale});
	}

	zoomOut=()=>{
		let pdfScale = this.state.pdfScale;

		pdfScale = Math.min(pdfScale+0.1, 2);

		this.setState({pdfScale:pdfScale});
	}

	nextPage = ()=>{
		this.goto(Math.min(this.props.streetIndex.totalPages, this.props.streetIndex.page+1));
	}

	previousPage = ()=>{
		this.goto(Math.max(1,this.props.streetIndex.page-1));
	}

	goto = (page, highlight=false, target={district:"", masterPageNum:0})=>{
		page = parseInt(page);
		if (page>this.props.streetIndex.totalPages) {
			page = this.props.streetIndex.totalPages;
		}
		if (page<1) {
		 	page = 0;
		}

		if (highlight) {
			this.setState({
				currentPage:highlight?target.masterPageNum:page,
				isHighlighted:highlight,
				highlightingResult:target
			});
		}
		else {
			this.setState({
				currentPage:highlight?target.masterPageNum:page,
				isHighlighted:highlight,
				highlightingResult: null
			});
		}

		// highlight = (highlight && this.isSafari)
		if (page !== 0) {this.props.changePage(page, false, target.district, target.matchedSeq);}
	}

	lookForWord = (original, currentFragment, caseSensitive, wholeWords, startIndex=0) => {
		// wholeWords ? Utility.wholeWordIndexOf(original, currentFragment, caseSensitive, startIndex) : 
		let pos = Utility.anyIndexOf(original, currentFragment, caseSensitive, startIndex) ;

		return pos;
	}

	getMatchObject = (elem, index, pos, length) => { return { elem:elem, index:index, pos:pos, length:length, next:[] } }

	

	highlightKeywordOnPage = (elem, keyword, matchedSeq, caseSensitive, wholeWords) => {
		let spacesCount = 0;
		let trimmedKeyword = keyword.trim();
		
		for (let l=0; l<trimmedKeyword.length;l++)
			if (trimmedKeyword[l] === ' ')
				spacesCount++;
		
		let sanitized = keyword; 

		for (let i=sanitized.length-1; i>=0; i--) {
			// special handling for ExtA/B chars -- they seem to be doubled in text layer?
			if (sanitized.codePointAt(i) > 0xFFFF) 
				sanitized = sanitized.substring(0, i) + " " + String.fromCodePoint(sanitized.codePointAt(i)) 
														+ String.fromCodePoint(sanitized.codePointAt(i)) + " " + sanitized.substring(i+2);

			// split string by character to search for -- fix unhighlightables due to PDF split chunks for same word (correction-al)
			else 
			{
				sanitized = sanitized.slice(0, i) + " " + sanitized.slice(i,i+1) + " " + sanitized.slice(i+1);
			}
		}

		sanitized = sanitized.trim()
						.replace(/\./g, ' . ')
						.replace(/\(/g, ' ( ')
						.replace(/\)/g, ' ) ')
						.replace(/\//g, ' / ')
						.replace(/-/g, ' - ')
						.replace(/\&/g, ' &amp; ')
						.replace(/,/g, ' , ')
						.replace(/ {2}/g, ' ')
						.replace(/ {2}/g, ' ')
						.replace(/ {2}/g, ' ')
						.replace(/ {2}/g, ' ')
						;

		// sanitize and split keywords
		let sanitizedSplit = sanitized.split(' ').filter(s => s !== ""); /// TODO

		let k = null;
		for (k in sanitizedSplit){
			this.log("split: <" + sanitizedSplit[k] + ">");
		}

		// locate the spans in elem containing the first keyword
		let matchArrs = [];
		let scaleXMin = 1.0000;

		Array.from(elem.children).forEach(
			(span, i) => {  
				
				if (span.innerHTML.indexOf("The SI and CRT are provided for online") >= 0 || span.innerHTML.indexOf("or to transmit the SI and CRT ") >= 0
					|| span.innerHTML.indexOf("或 將 街 道 索 引 及 對 照 表") >= 0 || span.innerHTML.indexOf("街 道 索 引 及 對 照 表 只 供 網 上 瀏 覽") >= 0
					|| span.innerHTML.trim() === "")
				{
					return; 
				}

				this.log("span=" + span.innerHTML + ", i=" + i); 				
				let pos = -1;
				this.log("current fragment = <" + sanitizedSplit[0] + ">");
				do {
					pos = this.lookForWord(span.innerHTML, sanitizedSplit[0], caseSensitive, wholeWords, pos+1);

					if (pos >= 0) this.log("==============" + (wholeWords ? "wholeWordIndexOf" : "anyIndexOf") + " pos=" + pos + " ==============" ); 

					// for whole word match, either the word must be found at the beginning, or it is preceded by a space.
					if (wholeWords && 
						!(pos === 0 || 
							Utility.isSpaceOrPunctuation(span.innerHTML[pos]) || Utility.isSpaceOrPunctuation(span.innerHTML[pos-1]))) {
						this.log("continuing for failed whole word match for first word");
						continue;
					}

					if (pos < 0) break;
					let match = this.getMatchObject(span, i, pos, sanitizedSplit[0].length);

					let currentSpanIndex = i;
					let currentSpan = elem.children[i];
					let prevMatchLastIndex = pos + sanitizedSplit[0].length;
					// find next fragments of the keyword in next fragments.
					for (let j=1; j<sanitizedSplit.length; j++) {
						let nextFragment = sanitizedSplit[j];
						this.log("next fragment = <" + nextFragment + ">");

						let nextPos = this.lookForWord(currentSpan.innerHTML, nextFragment, caseSensitive, wholeWords, prevMatchLastIndex);
						
						if (nextPos >= 0) this.log("+++++++++++++" + (wholeWords ? "wholeWordIndexOf" : "anyIndexOf") + " nextPos=" + nextPos + " +++++++++++++" ); 

						// for whole word match, either the word is up to last char in the span
						// or its next char is space.  otherwise the match failed
						if (wholeWords 
							&& nextPos >= 0
							&& j === sanitizedSplit.length-1
							&& !((nextPos + nextFragment.length === currentSpan.innerHTML.length)
								|| Utility.isSpaceOrPunctuation(nextFragment[nextFragment.length-1])
								|| Utility.isSpaceOrPunctuation(currentSpan.innerHTML[nextPos + nextFragment.length]))) {
							this.log("reset nextPos to -1 for not fulfilling whole words last word rule");
							nextPos = -1
						}

						if (nextPos < 0) { // next fragment is not found in current span, look in next non-space span?
							// if there are remaining non-space chars in current span, break.
							if (currentSpan.innerHTML.substring(prevMatchLastIndex).trim() !== "") {
								this.log("break for remaining chars in span but no match found.");
								break;
							}

							do {
								currentSpanIndex++;
								currentSpan = elem.children[currentSpanIndex];
								prevMatchLastIndex = 0
							} while (currentSpanIndex < elem.children.length && currentSpan.innerHTML.trim() === "") 

							if (currentSpan === undefined) {
								this.log("breaking for no more non space spans to search for");
								break; // if no more non-space spans, break.
							}

							this.log("current next span = <" + currentSpan.innerHTML + ">");

							nextPos = this.lookForWord(currentSpan.innerHTML, nextFragment, caseSensitive, wholeWords);
	
							if (nextPos >= 0) this.log("+++++++++++++" + (wholeWords ? "wholeWordIndexOf" : "anyIndexOf") + " nextPos=" + nextPos + ", currentSpanIndex=" + currentSpanIndex + " +++++++++++++" ); 
						
							// for whole word match, either the word is up to last char in the span
							// or its next char is space.  otherwise the match failed
							if (wholeWords 
								&& j === sanitizedSplit.length-1 
								&& !((nextPos + nextFragment.length === currentSpan.innerHTML.length)
									|| Utility.isSpaceOrPunctuation(nextFragment[nextFragment.length-1])
									|| Utility.isSpaceOrPunctuation(currentSpan.innerHTML[nextPos + nextFragment.length]))) {
								this.log("breaking for failed whole word match for last word");
								break; 
							}

							// if word is also not found in next span, than this match shall be ignored.
							if (nextPos >= 0 && (nextPos === prevMatchLastIndex || currentSpan.innerHTML.substring(prevMatchLastIndex, nextPos).trim() === "")) {
								this.log("++++++++++++ Added to next match +++++++++++++");
								match.next.push(this.getMatchObject(currentSpan, currentSpanIndex, nextPos, nextFragment.length));
								prevMatchLastIndex = nextPos + nextFragment.length;
							}
							else 
								break;
						} else { 
							// if next fragment is found in current span and it is the next word after prev fragment, create nextMatch and add to next array in match object
							this.log("currentSpan.innerHTML.substring(" + prevMatchLastIndex + ", " + nextPos + ") = <" + currentSpan.innerHTML.substring(prevMatchLastIndex, nextPos) + ">");
							if (nextPos === prevMatchLastIndex || currentSpan.innerHTML.substring(prevMatchLastIndex, nextPos).trim() === "") {
								this.log("++++++++++++ Added to next match +++++++++++++");
								match.next.push(this.getMatchObject(currentSpan, currentSpanIndex, nextPos, nextFragment.length));
								prevMatchLastIndex = nextPos + nextFragment.length;
							}
							else {// if not, no need to look further as the next match is separated by other interim text
								this.log("breaking for disjoint fragments");
								break;
							}
						}
					}

					if (match.next.length + 1 === sanitizedSplit.length) {
						this.log("added new match object to matchArrs: " + match.index + ", " + match.pos);
						matchArrs.push(match);
					}
					
				} while (pos >= 0)
			}
		);
		
		//for (let q = 0; q < matchArrs.length; q++) {
		let workedMatches = [];
		let highlightingMatch = matchArrs[matchedSeq];
		let unaddedSpaces = spacesCount;
		if (highlightingMatch !== undefined) {
			let spaceToAdd = '';
			let x=highlightingMatch.next.length-1;
			for (; x>=0; x--) {
				let nextMatch = highlightingMatch.next[x];
				let nextMatchHTML = nextMatch.elem.innerHTML;
				
				if (trimmedKeyword[x+unaddedSpaces+1] === ' ')
				{
					spaceToAdd = ' ';
					unaddedSpaces --;
				} 

				if (nextMatch.elem.className === "pdfHighlightSIRoadName") { spaceToAdd=' ' }

				nextMatch.elem.innerHTML = nextMatchHTML.slice(0, nextMatch.pos)
												+ '<span class="pdfHighlightedKeyword">' + nextMatchHTML.slice(nextMatch.pos, nextMatch.pos + nextMatch.length) + spaceToAdd + '</span>'
												+ nextMatchHTML.slice(nextMatch.pos + nextMatch.length);

				if (nextMatch.elem.className !== "pdfHighlightSIRoadName") {
					nextMatch.elem.innerHTML = nextMatch.elem.innerHTML.replace(/>[ ]*</g, '');
				}

				spaceToAdd = '';

				if (workedMatches.find(o => o == nextMatch.elem) === undefined)
					workedMatches.push(nextMatch.elem);
			}
				
			if (trimmedKeyword[x+unaddedSpaces+1] === ' ')
			{
				spaceToAdd = ' ';
				unaddedSpaces --;
			} 
			
			if (highlightingMatch.elem.className === "pdfHighlightSIRoadName") { spaceToAdd=' ' }

			highlightingMatch.elem.innerHTML = highlightingMatch.elem.innerHTML.slice(0, highlightingMatch.pos)
												+ '<span class="pdfHighlightedKeyword">' + highlightingMatch.elem.innerHTML.slice(highlightingMatch.pos, highlightingMatch.pos + highlightingMatch.length) + spaceToAdd + '</span>'
												+ highlightingMatch.elem.innerHTML.slice(highlightingMatch.pos + highlightingMatch.length);

			if (highlightingMatch.elem.className !== "pdfHighlightSIRoadName") {
				highlightingMatch.elem.innerHTML = highlightingMatch.elem.innerHTML.replace(/>[ ]*</g, '');
			}
			
			if (workedMatches.find(o => o == highlightingMatch.elem) === undefined)
				workedMatches.push(highlightingMatch.elem);
		}

		if (workedMatches.length > 0) {
			for (let k=0; k<workedMatches.length;k++) {
				let elem = workedMatches[k];
				if (Utility.highlightPatchFunction(elem)) {
					// if (elem.innerHTML.indexOf('  ') >= 0) {
					elem.innerHTML = elem.innerHTML.replace(/  /g,'');
					// }
					// setTimeout(() => {
					// 	elem.style.transform = "scaleX(" + scaleXMin + ") translateY(-9.4%)";
					// }, Utility.HIGHLIGHT_PATCH_TIMEOUT);
					
				}
			}
		}	

		// }
		this.log(matchArrs);
		
		let textLayer = this.getTextLayer();
		setTimeout(() => {
			// console.log(new Date() + " transform timeout");

			Array.from(textLayer.children).forEach(elem => {
				// remove watermark elements
				/*
				if (parseFloat(elem.style.top) / parseFloat(document.querySelector(".react-pdf__Page__textContent").style.height) >= 0.92) {
					elem.parentNode.removeChild(elem);
					return;
				}
				*/
				let transform = elem.style.transform;
				let scaleXStartPos = transform.indexOf("scaleX(");
				if (scaleXStartPos >= 0) {
					let scaleXEndPos = transform.indexOf(")", transform.indexOf("scaleX("));
					let scaleValue = parseFloat(transform.substring(scaleXStartPos+7, scaleXEndPos));

					if (scaleValue > 1.3) {
						elem.style.transform = transform.slice(0, scaleXStartPos) + " scaleX(1.1) " + transform.slice(scaleXEndPos+1);
					}
				}

				for (let i=0; i<workedMatches.length; i++) {
					let elem = workedMatches[i];
					Utility.specialCaseHighlightFunction(this.props.streetIndex.refType, this.state.currentPage, textLayer, elem, 
						elem.querySelector('span.pdfHighlightedKeyword').innerText);
				}
			});
		}
		, Utility.HIGHLIGHT_PATCH_TIMEOUT);
		
	};

	goToDistrict = districtIndex => {
		let district = this.props.streetIndex.districtsList[districtIndex];
		if (district === undefined) {
			return;
		}

		let pageIndices = this.props.streetIndex.pageIndices[district.code];

		let targetPage = 0;
		for(let i=0;i<pageIndices.length;i++){
			targetPage = pageIndices[i].firstPageMaster;
			if (targetPage > 0) {
				break;
			}
		}

		this.goto(targetPage);

	}

	search=()=>{
		let { caseSensitive, wholeWords, searchKey, searchCount } = this.state;

		if (this.state.searchKey 
			&& this.state.searchKey.trim && this.state.searchKey.trim().length > 0
			&& this.state.searchKey.trim() !== ""
			&& this.state.searchKey.trim() !== ";"
			&& this.state.searchKey.trim() !== "|"
			&& this.state.searchKey.trim() !== "||") {
			this.props.searchKeyword(caseSensitive, wholeWords, searchKey, searchCount);
		} else {
			this.props.searchKeywordError({message:"Invalid search key.  查找內容不正確。"});
			this.setState({hasError:true})
		}
	}

	searchMore=()=>{
		this.props.searchMore();
	}

	clearSearch=()=>{
		this.props.clearSearch();
	}

	boldText=(matchedPrefix,matched,matchedSuffix)=>{
		//let begin = phase.toUpperCase().search(word.toUpperCase())
		//let subString = phase.substr(begin, word.length)
		let newStr = (
			<p className="searchResultListText">
				{matchedPrefix/*phase.substr(0,begin)*/}
				<b>{matched/*subString*/}</b>
				{matchedSuffix/*phase.substr(begin+word.length)*/}
			</p>)

		return newStr;
	}

	hasMore=()=>{
		let { streetIndex } = this.props;

		// if (streetIndex.searchResultList.length !== 1
		// 	&& streetIndex.searchResultList.length % this.state.searchCount === 1) {
		// 	return true;
		// }
		// return false;

		return streetIndex.ttlNumOfResult > streetIndex.searchResultList.length;
	}

	constructSearchResultUI=()=>{
		let { streetIndex } = this.props;

		if (streetIndex.searchResultList === null) return null;
		
		let list = [];
		for (let i = 0; i < streetIndex.searchResultList.length; i++) {
			let prefix = streetIndex.searchResultList[i].matchedPrefix;
			let suffix = streetIndex.searchResultList[i].matchedSuffix;

			// handle marginal encoded chars...
			if (prefix.indexOf("|") >= 0 && prefix.indexOf("|") < prefix.length / 2) {
				prefix = prefix.substring(prefix.indexOf("|")+1)
			}
			// handle marginal encoded chars...
			if (suffix.indexOf("|") >= 0 && suffix.indexOf("|") > suffix.length / 2) {
				suffix = suffix.substring(0, suffix.indexOf("|"))
			}


			list.push(
				<li key={i}>
    				<button type="button"
						style={{border:"none", backgroundColor:"transparent"}}
						onClick={()=>{this.goto(streetIndex.searchResultList[i].pageNum, true, streetIndex.searchResultList[i])}}>
						{this.boldText(
							streetIndex.searchResultList[i].matchedPrefix, 
							streetIndex.searchResultList[i].fragment, 
							streetIndex.searchResultList[i].matchedSuffix)}
					</button>
    			</li>);
		}

		let bottomUI = null;

		if (this.hasMore()) {
			bottomUI = (
				<Row
					className="align-items-center justify-content-center" >

					<button
						type="button"
						style={{border:"none", backgroundColor:"transparent"}}
						onClick={this.searchMore} >
						<IconContext.Provider value={{ color: "green", size:"30px" }}>
							<FaCaretDown />
						</IconContext.Provider>
					</button>
					<div 
						className="showMore" 
						onClick={this.searchMore}>
						Show More 顯示更多
					</div>

				</Row>);
		}
		else {
			if (streetIndex.searchResultList.length > 0) {
				bottomUI = (
					<Row
						className="align-items-center justify-content-center" >
						<div>
							{"Total 總共: " + streetIndex.searchResultList.length}
						</div>

					</Row>);
			}
			else {
				bottomUI = null;
			}
		}
		
		return (
			<div style={{flex:1}}>
				<ul className="searchList">
					{list}
				</ul>
				{bottomUI}
			</div>);
	}

	translatePageIndicesLabel=code=>{
		switch(code) {
			case "0":
				return "Number"
			case "PIER":
				return "Permanent Pier";
			case "ABBR":
				return "Abbreviations";
			case "ENG":
				return "English";
			case "CHI":
				return "中文";
			case "BLANK":
				return "Blank Address";
			default:
				return code;
		}
	}

	constructPageIndicesButton=()=>{
		
		let returnObj = {UIComponent:null, selectedDistrictIndex:-1}

		// Get current district
		let districtsList = this.state.orderedDistrictList;
		if (districtsList === undefined || districtsList.length <= 0) {
			return returnObj;
		}

		let selectedDistrict = districtsList[0].district;
		let selectedDistrictIndex = -1;
		let diff = 9999;
		for(let i=0;i<districtsList.length;i++) {
			if (this.props.streetIndex.page >= districtsList[i].firstPageMaster
				&& diff > this.props.streetIndex.page - districtsList[i].firstPageMaster) {
				diff = this.props.streetIndex.page - districtsList[i].firstPageMaster;
				selectedDistrict = districtsList[i].district;
				selectedDistrictIndex=i;
			}
		}
		let pageIndicesButton = [];
		let pageIndices = this.props.streetIndex.pageIndices[selectedDistrict];
		let selectedPageIndex = -1;

		// Construct Button
		for(let i = 0; i < pageIndices.length; i++) {
			if (pageIndices[i].firstPageMaster > 0
				&& this.props.streetIndex.page >= pageIndices[i].firstPageMaster) {
				selectedPageIndex = i;
			}
			pageIndicesButton.push(
				<button key={i} type="button"
					className="pageIndex"
					onClick={()=>{this.goto(pageIndices[i].firstPageMaster)}} >
					{this.translatePageIndicesLabel(pageIndices[i].prefix)}
				</button>);
		}

		// Selected
		if (selectedPageIndex >= 0) {
			pageIndicesButton[selectedPageIndex] = (
					<button key={selectedPageIndex} type="button"
						className="pageIndex selectedPageIndex"
						onClick={()=>{this.goto(pageIndices[selectedPageIndex].firstPageMaster)}} >
						{this.translatePageIndicesLabel(pageIndices[selectedPageIndex].prefix)}
					</button>);
		}

		returnObj.UIComponent = pageIndicesButton;
		returnObj.selectedDistrictIndex = selectedDistrictIndex;
		
		return returnObj;
	}


	disableCaptcha = d => {
		Utility.disableCaptcha = d;
	}

	getTextLayer = () => {
		return this.isSafari?document.querySelector('.textLayer'):document.querySelector('.react-pdf__Page__textContent')
	}

	onReactPDFGetTextSuccess = () => {
		//console.log(new Date() + " onReactPDFGetTextSuccess");

		if (this.state.isHighlighted) {
			let textLayer = this.getTextLayer();
			this.log(textLayer);

			
			Array.from(textLayer.children).forEach(elem => {
				var elemInnerText = elem.innerText.trim().replace(/ +/g, ' ');
				var text = [...elemInnerText];
				var oddElems = text.filter((a, i) => i % 2 == 0) ;
				var evenElems = text.filter((a, i) => i % 2 == 1) ;

				if (parseFloat(elem.style.top) / parseFloat(document.querySelector(".react-pdf__Page__textContent").style.height) >= 0.92
				|| elem.innerText.indexOf("Address  地址") >= 0
				|| elem.innerText.indexOf("Building/Development Name  樓宇/屋苑名稱" ) >= 0
				|| elem.innerText.indexOf("Lot Description  地段資料" ) >= 0) { 
					elem.parentNode.removeChild(elem);
					return;
				}
				if (oddElems.length === evenElems.length + 1 && oddElems.every(c => !Utility.isASCII(c)) && evenElems.every(c => Utility.isSpace(c)))
					elem.className = "pdfHighlightSIRoadName";
			});
			
			this.highlightKeywordOnPage(textLayer, this.state.searchKey, this.state.highlightingResult.matchedSeq, this.state.caseSensitive, this.state.wholeWords);

			if (this.debug) document.getElementById("disableCaptcha").click();

		}
	}

	static isSafari=false;
	componentDidMount() {
		let param = window.location.href.replace(window.location.origin+"/","")

		switch(param) {
		case "CRT":
		case "SI":
			this.props.setType(param);
			this.props.retrieveInfo();
			break;
		default:
			param="";
			break;
		}

		let title = "The Land Registry / 土地註冊處"
		document.title = title;

		window.addEventListener('resize', ()=>{this.setState({resizeTrigger:!this.resizeTrigger})});
		
		this.isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
		// this.isSafari = !this.isSafari;

		// if (this.isSafari) {
		// 	this.setState({hasInfo:true, infoMsg:["Some functions are not supported on your browser, you are adviced to use Chrome/FireFox for better experience.\n\n你的瀏覽器並不支援部分功能，建議使用Chrome或Firefox為佳。"]})
		// }
		
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.streetIndex.districtsList.length === 0
			&& this.props.streetIndex.districtsList.length > 0) {
			// Init lists
			let list = this.props.streetIndex.districtsList;
			let districtTabs = []
			for(let i = 0; i < list.length; i++) {
				districtTabs.push(
					<Tab key={i}
					eventKey={""+i}
					title={list[i].name}
					onChange={()=>{}} />
					);
			}

			this.setState({
				districtTabs:districtTabs
			});

			let orderedDistrictList=[];
			for(var key in this.props.streetIndex.pageIndices) {
				let obj = this.props.streetIndex.pageIndices[key];
				
				let firstPage = 0;
				for(let i=0;i<obj.length;i++){
					firstPage = obj[i].firstPageMaster;
					if (firstPage > 0) {
						break;
					}
				}


				orderedDistrictList.push({district:key, firstPageMaster:firstPage});
			}

			orderedDistrictList = Utility.mergeSort(orderedDistrictList, "district");
			this.setState({orderedDistrictList:orderedDistrictList});
		}

		if (prevProps.streetIndex.errMsg.length === 0
			&& this.props.streetIndex.errMsg.length > 0) {
			this.setState({hasError:true})
		}

		if (this.isSafari
			&& prevProps.streetIndex.pdfData !== this.props.streetIndex.pdfData
			&& this.props.streetIndex.pdfData !== null) {
			// new Response(this.props.streetIndex.pdfData).arrayBuffer()
			// 	.then(r=>{this.setState({pdfDataBuffer:r})});

			var reader = new FileReader();
			reader.readAsDataURL(this.props.streetIndex.pdfData); 
			reader.onloadend = () => {
				var dataUrl = reader.result; 
				if (!dataUrl) return;
				var base64 = dataUrl.split(',')[1];               
				this.setState({pdfDataBuffer:base64});
			}
		}
	}

	renderPDF=(streetIndex, scale)=>{
		return (
			<PDFContent
				isSafari={this.isSafari}
				pdfData={streetIndex.pdfData}
				pdfDataBuffer={this.state.pdfDataBuffer}
				matchedSeq={this.state.highlightingResult!==null?this.state.highlightingResult.matchedSeq:-1}
				scale={scale}
				onReactPDFGetTextSuccess={this.onReactPDFGetTextSuccess}
			/>);
	}

	render() {
		let { streetIndex } = this.props;
		let {
			pdfScale, districtTabs, currentPage,
			caseSensitive, wholeWords, hasError,
			searchCount, hasInfo, infoMsg,
			} = this.state;
		let defaultNotice = (
			<div style={{ margin: "10px" }}>
				<p>Please click <a href={"http://" + Utility.REQUIRED_REFERRER_EN}>here</a> to accept the terms and conditions for usage of Free Online Browsing of the Index of Streets, House Numbers and Lots in Hong Kong, Kowloon and New Kowloon ("SI") and New Territories Lot/Address Cross Reference Table ("CRT").</p>
				<p>請 先 <a href={"http://" + Utility.REQUIRED_REFERRER_TC}>按 此</a> 閱 覽 並 接 受 免 費 網 上 瀏 覽 港 島 、 九 龍 及 新 九 龍 之 街 道 、 樓 宇 門 牌 號 數 和 地 段 索 引 （ 街 道 索 引 ） 及 新 界 地 段 ╱ 地 址 對 照 表 （ 對 照 表 ） 的 使 用 條 款 及 條 件 。</p>
				<p>请 先 <a href={"http://" + Utility.REQUIRED_REFERRER_CHS}>按 此</a> 阅 览 并 接 受 免 费 网 上 浏 览 港 岛 、 九 龙 及 新 九 龙 之 街 道 、 楼 宇 门 牌 号 数 和 地 段 索 引 （ 街 道 索 引 ） 及 新 界 地 段 ╱ 地 址 对 照 表 （ 对 照 表 ） 的 使 用 条 款 及 条 件 。</p>
			</div>
		);
		
		let scale = Math.max(window.innerWidth,1200)/850;
		switch (streetIndex.refType) {
		case "CRT":
			scale = Math.max(window.innerWidth,1200)/1250;
			break;
		case "SI":
			break;
		default:
			return defaultNotice;
		}
		scale = scale*pdfScale;

		let searchListColHeight = window.innerHeight + "px";
		let searchListHeight = (window.innerHeight-170) + "px";

		let totalSearchResult = "Showing 顯示中 ";
		if (streetIndex.searchResultList.length > 0) {
			totalSearchResult += "1-"
			totalSearchResult += streetIndex.searchResultList.length;
			totalSearchResult += " / "+streetIndex.ttlNumOfResult;
		}
		else {
			totalSearchResult = "\nNo Result Found\n沒有相符結果"
		}
		
		let searchResultUI = this.constructSearchResultUI();
		
		let obj = this.constructPageIndicesButton();

		let pageIndicesUI = obj.UIComponent;
		let selectedDistrictIndex = obj.selectedDistrictIndex
		
		let noPdfData = !(currentPage >= 1 || currentPage === "");
		let pdf = this.renderPDF(streetIndex,scale);
		
	    return (
	    	<Container className="rootContainer d-flex" style={{width:"100%"}} onContextMenu={(e)=> e.preventDefault()}>
				<div className={"loading-overlay " + (streetIndex.isFetching ? "" : "hidden")}>
					<div className="loader"></div>
				</div>
				<button 
					id="disableCaptcha"
					type="button"
	    			className="hidden"
					onClick={()=>{this.disableCaptcha(true)}}>
				</button>
				<GeneralModal
					title={ hasError ? "Error 錯誤" : "Information 訊息" }
					show={ hasError || hasInfo }
					msgArr={ hasError ? streetIndex.errMsg : infoMsg }
					closeLabel="Close 關閉"
					onHide={ ()=>{this.setState({hasError:false, hasInfo:false})} } />
				<Navbar className="headerBackground" style={{width:"97%", minHeight:"100px"}} >
		    		<Col className="topBar d-flex">
			    		<Row className="topBarRow">
			    			<Col className="lrLogoLeftCol">
					    		<a href ={"https://www.landreg.gov.hk/"} className="lrLogo">
					    			<img src={logo} className="lrLogo" style={{left:"20px"}} url={document.location.origin} alt=""/>
					    		</a>
				    		</Col>
				    		<Col className="title">
				    			<p>{streetIndex.title}</p>
				    		</Col>
				    		<Col className="lrLogoRightCol">
					    		
				    		</Col>
				    	</Row>
				    	<Row className="topBarButtonRow">
							{/* // SDSD confirmed on 14.4.2020 that the link to the other table is not required on the title section. */} 
				    	</Row>
		    		</Col>
		    	</Navbar>
		    	<Row style={{width:"97%",height:"100%"}}>
		    		<Col style={{maxWidth:"25%", height:searchListColHeight, paddingLeft:"0px", paddingRight:"0px"}}>
	    				{streetIndex.searchKey === "" 
	    					|| streetIndex.searchResultList.length <= 0 ? 
	    					<Col style={{width:"100%", height:searchListColHeight,
				    				backgroundColor:"WHITE",
				    				margin:"10px 0px 0px 0px",}} >
		    					<Col>
			    					<Row>
					    				<InputField
					    					containerClass="searchKeyInput"
					    					value={this.state.searchKey}
					    					onKeyPress={this.handleKeyPress}
					    					onChange={v=>{this.setState({searchKey:v})}}
					    				/>
					    				<IconContext.Provider value={{ color: "green", size:"30px" }}>
											<div className="d-flex align-items-center" style={{padding:"0px 15px 17px 5px", marginLeft:"-10px"}}>
												<button type="button"
													style={{border:"none", backgroundColor:"transparent"}}
													onClick={()=>{this.search()}}>
													<FaSearch />
												</button>
											</div>
										</IconContext.Provider>

				    				</Row>
				    				{streetIndex.searchKey !== "" ? 
				    					<Col className="noResultText">
				    						No Result Found<br/>沒有相符結果
				    					</Col>
				    					:
				    					null
				    				}
				    				<Row >
				    					<div className="criteria-label boldFont">
				    					{"Rows return per load\n每次加載行數"}
				    					</div>

					    				<InputField
					    					containerClass="d-flex flex-row align-items-center searchCountInput"
					    					inputType = "number"
					    					labelClass="hidden"
											value={searchCount}
											onKeyPress={this.handleKeyPress} 
					    					onChange={v=>{
												if (v < 0) v=0;
												if (v > 9999) v=9999;
												this.setState({searchCount:v})
											}}
					    				/>
				    				</Row>
				    				<Checkbox
				    					containerClass="checkbox"
				    					labelClass="criteria-label"
				    					label={"Whole Words Only\n全寫拼寫須相符"}
				    					checked={wholeWords}
				    					onChange={v=>{this.setState({wholeWords:v})}}
				    					/>
				    				<Checkbox
				    					containerClass="checkbox"
				    					labelClass="criteria-label"
				    					label={"Case Sensitive\n大小寫須相符"}
				    					checked={caseSensitive}
				    					onChange={v=>{this.setState({caseSensitive:v})}}
				    					/>
			    				</Col>
		    				</Col>
		    				:
		    				<Col style={{width:"100%", height:searchListColHeight,
			    				backgroundColor:"WHITE",
			    				margin:"10px 0px 0px 0px",}} >
			    				<Row style={{flexDirection:"column"}}>
				    				<Row >
				    					<IconContext.Provider value={{ color: "green", size:"30px" }}>
											<div className="d-flex align-items-center" >
												<button type="button"
													style={{border:"none", backgroundColor:"transparent"}}
													onClick={()=>{this.clearSearch()}}>
													<FaCaretLeft />
												</button>
											</div>
										</IconContext.Provider>
				    					<Col className="backToSearchText" onClick={()=>{this.clearSearch()}}>Back 返回</Col>
			    					</Row>
									<Row className="searchResultText" >
										{"Search Key 查找內容:  " + streetIndex.searchKey}
									</Row>
									<Row className="readonlyCheckBoxRow" >
										{wholeWords?
											<Checkbox
						    					containerClass="readonlyCheckBox"
						    					readonly={true}
						    					label={"Whole Words Only\n全寫拼寫須相符"}
						    					checked={wholeWords}
					    						onChange={()=>{}}
						    					/>
						    				:
						    				null}
									</Row>
									<Row className="readonlyCheckBoxRow" >
										{caseSensitive?
						    				<Checkbox
						    					containerClass="readonlyCheckBox"
						    					readonly={true}
						    					label={"Case Sensitive\n大小寫須相符"}
						    					checked={caseSensitive}
					    						onChange={()=>{}}
						    					/>
						    				:
						    				null}
									</Row>
									<Row className="showingText" >
										{totalSearchResult}
									</Row>
		    					</Row>
			    				<Row className="searchResultList" style={{minHeight:searchListHeight, maxHeight:searchListHeight}}>
			    					{searchResultUI}
		    					</Row>
		    				</Col>
		    			}
		    		</Col>
		    		<Col style={{maxWidth:"75%", height:"100%", paddingRight:"0px"}}>
		    			<Col style={{width:"100%", height:"95%", backgroundColor:"WHITE", margin:"10px 0px 0px 0px"}} >
		    				
							<Row className="align-items-center justify-content-center districtTab"
								style={{backgroundColor:"#FAFAFA" , height:"3%"}}>
								<Tabs 
									defaultActiveKey="0"
									transition={false}
									id="districts"
									activeKey={selectedDistrictIndex}
									onSelect={tabIndex => {this.goToDistrict(tabIndex)}} >
									{ districtTabs }
								</Tabs>
							</Row>
							<Row className="align-items-center justify-content-center"
								style={{backgroundColor:"#FAFAFA" , height:"3%"}}>

								{ pageIndicesUI }
							</Row>
		    				<Row className="align-items-center justify-content-center"
								style={{backgroundColor:"#FAFAFA" , height:"5%"}}>

								<button type="button"
									style={{border:"none", backgroundColor:"transparent"}}
									onClick={this.previousPage}
									>
									<IconContext.Provider value={{ color: "green", size:"30px" }}>
										<FaCaretLeft />
									</IconContext.Provider>
								</button>
								<input 
									className="normalInput normalFont form-control"
									style={{height:"30px", width:"70px"}}
									value={currentPage}
									onKeyPress={e=>{if(e.key === 'Enter') {this.goto(e.target.value);}}}
									onChange={e=>{this.setState({currentPage:e.target.value})}}/>
								<div className="align-items-center justify-content-center">
									<p className="pageNum" >{" / " + streetIndex.totalPages}</p>
								</div>
								<button type="button"
									style={{border:"none", backgroundColor:"transparent"}}
									onClick={this.nextPage}
									>
									<IconContext.Provider value={{ color: "green", size:"30px" }}>
										<FaCaretRight />
									</IconContext.Provider>
								</button>
								{this.isSafari ? 
									null
									:
									<div className="pdfZoomDiv">
										<div style={{position:"relative"}}>
											<button type="button"
												className="pdfZoomButton"
												onClick={this.zoomIn}
												>
												<IconContext.Provider value={{ color: "green", size:"17px" }}>
													<FaSearchMinus />
												</IconContext.Provider>
											</button>
											<button type="button"
												className="pdfZoomButton"
												onClick={this.zoomOut}
												>
												<IconContext.Provider value={{ color: "green", size:"17px" }}>
													<FaSearchPlus />
												</IconContext.Provider>
											</button>
										</div>
									</div>}
								

							</Row>
							
							<Row className="pdfCanvasDiv" style={(this.isSafari)?{overflowX:"hidden"}:{}}>
								<div style={{display:noPdfData?"none":"block", maxWidth:"100%"}}>
									{pdf}
								</div>
									
								<div style={{display:noPdfData?"block":"none",textAlign: 'center', margin: '40px', fontWeight: 'bold', width: '100%'}}>
									NO DATA FOR THIS ENTRY
								</div>
								
							</Row>
		    			</Col>
		    		</Col>
		    	</Row>
	    	</Container>
	    );
	}
}

const mapStateToProps = (state) => {
  return {
    streetIndex: state.streetIndex,
  }
}

export default connect(
  mapStateToProps,
  { changePage, searchKeyword, clearSearch, retrieveInfo, setType, searchMore, searchKeywordError }
) (StreetIndex);