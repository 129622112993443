const MAX_ACTION_COUNT = 50;
const MAX_ACTION_PERIOD = 90000;  //ms

const ACTION_ARRAY = []
const CONFIG = window.CONFIG;

export default class Utility {
  
  static HIGHLIGHT_PATCH_TIMEOUT = 500;   
  static REQUIRED_REFERRER_EN = "www0.landreg.gov.hk/en/public/pu-si_agree.htm";
  static REQUIRED_REFERRER_TC = "www0.landreg.gov.hk/tc/public/pu-si_agree.htm";
  static REQUIRED_REFERRER_CHS = "www0.landreg.gov.hk/chs/public/pu-si_agree.htm";

  static tokensArray = [];
  static searchCount = 5000;

  static b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };

  static highlightPatchFunction = CONFIG.highlightPatchFunction;

  static specialCaseHighlightList = CONFIG.specialCaseHighlight;
  static specialCaseHighlightFunction = (mode, currentPage, textLayer, elem, keyword) => {
    let matchedCases = (this.specialCaseHighlightList.filter(c => c.mode === mode && c.page === currentPage));

    let i=0
    for (i=0; i<matchedCases.length; i++) {
      let match = matchedCases[i];
      
      if (textLayer.children[match.chunkNumber] === elem )
        // && textLayer.children[match.chunkNumber].querySelector('span.pdfHighlightedKeyword').innerText === match.keyword)
      {
        console.log(textLayer.children[match.chunkNumber].querySelector('span.pdfHighlightedKeyword').innerText + " <-> " + match.keyword)
        if (elem.style.transform.indexOf(match.fixTransform) < 0) {
          console.log(elem.style.transform);

          elem.style.transform = elem.style.transform + " " + match.fixTransform;
          console.log(elem.style.transform);

        }
      }
    }
  };

  static isNonExtChineseChar = cc => (cc.charCodeAt(0) >= 0x4E00 && cc.charCodeAt(0) <= 0x9FFF) || (cc.charCodeAt(0) >= 0xF900 && cc.charCodeAt(0) <= 0xFAFF);
  static isASCII = cc => cc.charCodeAt(0) < 0xFF;
  static isSpace = cc => cc === " "
  static isSpaceOrPunctuation = cc => /[\(\)@.,:;`"\?\!\-\[\]\/\\ ]/.test(cc);
  static anyIndexOf = (original, search, caseSensitive, startIndex=0) => (caseSensitive?original:original.toLowerCase()).indexOf(caseSensitive?search:search.toLowerCase(),startIndex);
  static wholeWordIndexOf = (original, search, caseSensitive, startIndex=0) => {
    let foundIndex = Utility.anyIndexOf(original, search, caseSensitive, startIndex);

    if (foundIndex <= 0 || (original[foundIndex-1] === " " && (foundIndex+search.length >= original.length || original[foundIndex+search.length] === " "))) 
      return foundIndex;
    else { // recursion to find next match if word is not at beginning of match and is not wrapped by spaces
        return Utility.wholeWordIndexOf(original, search, caseSensitive, foundIndex + 1);      
    }
  };

  static compare=(left, right) => {
    if (left === "TP" && right === "TM") {
      return true;
    }
    else if (left === "TM" && right === "TP") {
      return false;
    }
    else if (left === "TW" && right === "TM") {
      return true;
    }
    else if (left === "TM" && right === "TW") {
      return false;
    }
    return left < right;
  };

  static merge = (left, right, targetObj) => {
    let resultArray = [], leftIndex = 0, rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {

      let isSpecCase = false;
      switch(left[leftIndex][targetObj]) {
        case "INTRO":
          resultArray.push(left[leftIndex]);
          leftIndex++;
          isSpecCase = true;
          break;
        case "ABBR":
        case "CHI":
          resultArray.push(right[rightIndex]);
          rightIndex++;
          isSpecCase = true;
          break;
        case "BLANK":
          resultArray.push(right[rightIndex]);
          rightIndex++;
          isSpecCase = true;
          break;
        default:
          break;
      }
      if (isSpecCase) continue;

      switch(right[rightIndex][targetObj]) {
        case "INTRO":
          resultArray.push(right[rightIndex]);
          rightIndex++;
          isSpecCase = true;
          break;
        case "ABBR":
        case "CHI":
          resultArray.push(left[leftIndex]);
          leftIndex++;
          isSpecCase = true;
          break;
        case "BLANK":
          resultArray.push(left[leftIndex]);
          leftIndex++;
          isSpecCase = true;
          break;
        default:
          break;
      }
      if (isSpecCase) continue;

      if (left[leftIndex][targetObj] === "PIER") {
        resultArray.push(right[rightIndex]);
        rightIndex++;
      }
      else if (right[rightIndex][targetObj] === "PIER"){
        resultArray.push(left[leftIndex]);
        leftIndex++;
      }
      else if (this.compare(left[leftIndex][targetObj],right[rightIndex][targetObj])) {
        resultArray.push(left[leftIndex]);
        leftIndex++; // move left array cursor
      }
      else {
        resultArray.push(right[rightIndex]);
        rightIndex++; // move right array cursor
      }

    }

    return resultArray
            .concat(left.slice(leftIndex))
            .concat(right.slice(rightIndex));
  };

  static mergeSort = (arr, targetObj) => {
    if (arr.length <= 1) {
      return arr;
    }
    const middle = Math.floor(arr.length / 2);

    const left = arr.slice(0, middle);
    const right = arr.slice(middle);

    // Using recursion to combine the left and right
    return this.merge(
      this.mergeSort(left, targetObj), this.mergeSort(right, targetObj), targetObj
      );
  };

  static disableCaptcha = false;
  static needCaptcha = ()=>{
    if (this.disableCaptcha) return false;

    var d = new Date();
    var time = d.getTime();
    ACTION_ARRAY.push(time);

    while(ACTION_ARRAY.length > 0) {
      if(ACTION_ARRAY[0] >= time - MAX_ACTION_PERIOD) {
        break;
      }
      else {
        ACTION_ARRAY.shift();
      }
    }

    // console.log("Action array: " + ACTION_ARRAY.length);
    if (ACTION_ARRAY.length > MAX_ACTION_COUNT) {
      this.afterNeededCaptcha();
      return true;
    }


    return false;
  }

  static afterNeededCaptcha = () => {
    while (ACTION_ARRAY.length > 0)
      ACTION_ARRAY.shift();
    // console.log("Action array cleared: " + ACTION_ARRAY.length);
  }

}