import {
	SET_TYPE, CLEAR_SEARCH,
	RETRIEVE_INFO, RETRIEVE_INFO_SUCCESS, RETRIEVE_INFO_ERROR,
	SEARCH_KEYWORD, SEARCH_KEYWORD_SUCCESS, SEARCH_KEYWORD_ERROR,
	SEARCH_MORE, SEARCH_MORE_SUCCESS, SEARCH_MORE_ERROR,
	CHANGE_PAGE, CHANGE_PAGE_SUCCESS, CHANGE_PAGE_ERROR,
	} from "./actionTypes";
import axios from 'axios';

import store from '../store.js';
import Utility from '../utility.js'

const MAX_SEARCH_COUNT = 9999;

const axiosRequestWithRecaptcha = (startedCallback, url, postBody) => {

	let pResolve  = null;
	// let pReject = null;
	let callback = startedCallback;
	let reqMethod = postBody === undefined ? "GET" : "POST";
	// console.log("is get: " +(reqMethod === "GET")); 
	// console.log("is post: " +(reqMethod === "POST"));

	if (Utility.needCaptcha()) {
		// Captcha
		let p = new Promise((rs, rj) => { 
			pResolve = rs;
			// pReject = rj;
		});

		if (callback) callback();

		window.performRecaptchaCompleted = () => { 
			let token =	document.getElementById('g-recaptcha-response').value;
			// console.log("token = " + (token !== "" ? token.substring(token.length - 32) : ""));

			if (Utility.tokensArray.includes(token)) {
				// console.log("repeated token");
				token = "";
			}

			pResolve(axios({
				url: url, 
				method: reqMethod,
				'Content-Type': 'application/json',
				headers: token !== "" ? { 'Access-Token': token } : {},  
				data: reqMethod === "POST" ? postBody : null
			} ));

			if (!Utility.tokensArray.includes(token)) {
				
				Utility.tokensArray.push(token);
			}
		}; 

		window.grecaptcha.ready(() => {
			// let token = document.getElementById('g-recaptcha-response').value;
			window.grecaptcha.reset();
			window.grecaptcha.execute();
			// if (token === "") {
			// 	console.log("recaptcha execute at " + new Date());
			// 	window.grecaptcha.reset();
			// 	window.grecaptcha.execute();			
			// } else {
				
			// 	// var cresponse = window.grecaptcha.getResponse();
			// 	// pResolve(axios.get(url, { headers: { 'Access-Token': cresponse }} ));
				
			// 	window.performRecaptchaCompleted();
			// }
		}); 

		return p;
	}
	else {
		// Pass
		if (callback) callback();
		return axios({
			url: url, 
			method: reqMethod,
			'Content-Type': 'application/json',
			data: reqMethod === "POST" ? postBody : null
		} );
	}
};

export const clearSearch = ()=>({
	type: CLEAR_SEARCH,
});

export const setType = refType=>({
	type: SET_TYPE,
	payload: refType,
})

export const retrieveInfo = content => {
	return dispatch => {
		
		try {

			let url = "/api/SICRTv1/index/" + store.getState().streetIndex.refType
			
			axiosRequestWithRecaptcha(
				() => dispatch(retrieveInfoStarted()),
				url,
			).then(res=> {
				switch(res.status) {
				case 200:
					if (res.data.result === "I001") {
						dispatch(retrieveInfoSuccess(res.data.data));
					}
					else {
						dispatch(retrieveInfoError(res));
					}
					break;
				default:
					dispatch(retrieveInfoError(res));
					break;
				}

			}).catch(err=>{
				dispatch(retrieveInfoError(err));
				console.error("retrieveInfo: [ERROR] " + err);
			});

		} catch (err) {
			dispatch(retrieveInfoError(err));
			console.error("retrieveInfo: [ERROR] " + err);
		}
	};
};

const retrieveInfoStarted = () => ({
	type: RETRIEVE_INFO,
});

const retrieveInfoSuccess = data => ({
	type: RETRIEVE_INFO_SUCCESS,
	payload: data,
});

const retrieveInfoError = data => ({
	type: RETRIEVE_INFO_ERROR,
	payload: data,
});

export const searchKeyword = (caseSensitive, wholeWords, key, searchCount) => {
	return dispatch => {
		try {

			if (searchCount > MAX_SEARCH_COUNT)
				dispatch(searchKeywordError({ message: "Max count is " + MAX_SEARCH_COUNT }));

			// let url = "/api/SICRTv1/search/" + store.getState().streetIndex.refType
			// url = url + "/" + (caseSensitive?"1":"0") + (wholeWords?"1":"0")
			// url = url + "/ALL/" + (btoa(encodeURIComponent(key))).replace('/', '_');
			// url += "/1/" + (parseInt(searchCount));
			let body = {};
			body.refType = store.getState().streetIndex.refType;
			body.searchOptionFlags = (caseSensitive?"1":"0") + (wholeWords?"1":"0");
			body.districts = "ALL";
			body.start = 1;
			body.perpage = (parseInt(searchCount));

			body.keywords = (btoa(encodeURIComponent(key))).replace('/', '_');

			axiosRequestWithRecaptcha(
				() => dispatch(searchKeywordStarted()),
				"/api/SICRTv1/search",
				body
			).then(res=> {
				switch(res.status) {
				case 200:
					dispatch(searchKeywordSuccess(key, res.data.data, caseSensitive, wholeWords, searchCount));
					
					break;
				default:
					dispatch(searchKeywordError(res));
					break;
				}

			}).catch(err=>{
				dispatch(searchKeywordError(err));
				console.error("searchKeyword: [ERROR] " + err);
			});

		} catch (err) {
			dispatch(searchKeywordError(err));
			console.error("searchKeyword: [ERROR] " + err);
		}
	};
};

const searchKeywordStarted = () => ({
	type: SEARCH_KEYWORD,
});

const searchKeywordSuccess = (key, result, caseSensitive, wholeWords, searchCount) => ({
	type: SEARCH_KEYWORD_SUCCESS,
	payload: {
		searchKey:key,
		ttlNumOfResult:result.counts,
		searchResultList:result.results,
		caseSensitive:caseSensitive,
		wholeWords:wholeWords,
		searchCount:searchCount,
	},
});

export const searchKeywordError = data => ({
	type: SEARCH_KEYWORD_ERROR,
	payload: data,
});

export const searchMore = () => {
	return dispatch => {
		try {
			let { refType, wholeWords, caseSensitive, searchKey, searchCount, currentSearchIndex } = store.getState().streetIndex;
			

			// let url = "/api/SICRTv1/search/" + store.getState().streetIndex.refType
			// url = url + "/" + (caseSensitive?"1":"0") + (wholeWords?"1":"0")
			// url = url + "/ALL/" + (btoa(encodeURIComponent(key))).replace('/', '_');
			// url += "/1/" + (parseInt(searchCount));
			let body = {};
			body.refType = refType;
			body.searchOptionFlags = (caseSensitive?"1":"0") + (wholeWords?"1":"0");
			body.districts = "ALL";
			body.start = currentSearchIndex;
			body.perPage = (parseInt(searchCount));

			body.keywords = (btoa(encodeURIComponent(searchKey))).replace('/', '_');
			
			axiosRequestWithRecaptcha(
				() => dispatch(searchMoreStarted()),
				"/api/SICRTv1/search",
				body
			).then(res=> {
				switch(res.status) {
				case 200:
					dispatch(searchMoreSuccess(res.data.data));
					
					break;
				default:
					dispatch(searchMoreError(res));
					break;
				}

			}).catch(err=>{
				dispatch(searchMoreError(err));
				console.error("searchMore: [ERROR] " + err);
			});

		} catch (err) {
			dispatch(searchMoreError(err));
			console.error("searchMore: [ERROR] " + err);
		}
	};
};

const searchMoreStarted = () => ({
	type: SEARCH_MORE,
});

const searchMoreSuccess = (result) => ({
	type: SEARCH_MORE_SUCCESS,
	payload: {
		ttlNumOfResult:result.counts,
		searchResultList:result.results,
	},
});

const searchMoreError = data => ({
	type: SEARCH_MORE_ERROR,
	payload: data,
});

export const changePage = (page, highlight, district, matchedSeq) => {
	return dispatch => {
		try {
			let url = "";
			let { streetIndex } = store.getState();
			if (highlight) {
				
				url = "/api/SICRTv1/highlight"; // " + streetIndex.refType;
				// url += "/" + (streetIndex.caseSensitive?"1":"0") + (streetIndex.wholeWords?"1":"0");
				// url += "/" + district; 
				// url += "/" + page;
				// url += "/" + (btoa(encodeURIComponent(streetIndex.searchKey))).replace('/', '_');
				// url += "/" + matchedSeq;

				var body = {};
				body.refType = streetIndex.refType;
				body.searchOptionFlags = (streetIndex.caseSensitive?"1":"0") + (streetIndex.wholeWords?"1":"0");
				body.districtCode = district;
				body.pageNum = page;
				body.keyword = (btoa(encodeURIComponent(streetIndex.searchKey))).replace('/', '_');;
				body.matchSeq = matchedSeq;

			}
			else {
				url = "/api/SICRTv1/page/" + streetIndex.refType + "/num/";
				url = url + page;
			}

			axiosRequestWithRecaptcha(
				() => dispatch(changePageStarted()),
				url,
				body
			).then(res=> {				
				
				switch(res.status) {
				case 200:
					dispatch(changePageSuccess(page, res.data.data));
					
					break;
				default:
					dispatch(changePageError(res));
					break;
				}

			}).catch(err=>{
				dispatch(changePageError(err));
				console.error("changePage: [ERROR] " + err);
			});

		} catch (err) {
			dispatch(changePageError(err));
			console.error("changePage: [ERROR] " + err);
		}
	};
};

const changePageStarted = () => ({
	type: CHANGE_PAGE,
});

const changePageSuccess = (page, data) => ({
	type: CHANGE_PAGE_SUCCESS,
	payload: {page:page, pdfData:data},
});

const changePageError = data => ({
	type: CHANGE_PAGE_ERROR,
	payload: data,
});