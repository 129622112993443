import React from 'react';
import Col from 'react-bootstrap/Col';

import '../../css/input.css'


export default class Checkbox extends React.Component {

	handleChange=e=>{
		this.props.onChange(e.target.checked);
	}

	render() {

		let { 
			label, checked, containerClass, labelClass, readonly,
		} = this.props;
		
		containerClass = (containerClass?containerClass:"");

		labelClass = "inputLabel boldFont " + (labelClass?labelClass:"");
				
	    return (
			<Col className={"inputContainer d-flex " + containerClass}>
				{
	    			readonly ? 
		    			(checked ?
		    				<div>&#10004;</div>
		    				:
		    				<div>&#9744;</div>
		    				)
		    			:
		    			<input id={"id_" + label} type="checkbox" checked={checked} onChange={this.handleChange} />
	    		}
				<label className={labelClass} htmlFor={"id_" + label}>{label}</label>
		  	</Col>
	  	);
		
	}
}
