import React from 'react';

import { pdfjs, Document, Page } from 'react-pdf';
import PDFViewer from 'pdf-viewer-reactjs'
// import "../../node_modules/pdfjs-dist/web/pdf_viewer.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default class PDFContent extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			numPages:0,
			triggerRender:false,
		}
	}

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.state.numPages === 0 && nextState.numPages === 1) {
			return true;
		}
		if (this.state.triggerRender === true && nextState.triggerRender === false) {
			return false;
		}

		if (!nextState.triggerRender
			&& this.props.pdfData === nextProps.pdfData
			&& this.props.pdfDataBuffer === nextProps.pdfDataBuffer
			&& this.props.scale === nextProps.scale
			&& this.props.matchedSeq === nextProps.matchedSeq) {
			return false;
		}

		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.triggerRender) {
			this.setState({ triggerRender:false });
		}
	}

	render() {
		let { 
			isSafari, pdfData, pdfDataBuffer, matchedSeq,
			scale, onReactPDFGetTextSuccess,
		} = this.props;
		let { numPages, triggerRender } = this.state;

		if (isSafari) {
			window.PDFJS.cMapUrl = 'cmaps/';
			window.PDFJS.cMapPacked = true;
			return (
				pdfDataBuffer ?
					<PDFViewer
						scale={scale}
						scaleStep={0.1}
						maxScale={2.0}
						minScale={0.5}
						document={{base64: pdfDataBuffer,}}
						css="pdfViewer"
						canvasCss="pdfViewerCanvas"
						navigation={{css:{previousPageBtn:"previousPageBtn",pageIndicator:"pdfViewerPage",nextPageBtn:"nextPageBtn"}}}
						protectContent={true}
						navbarOnTop={true}
						hideRotation={true}
						matchedSeq={matchedSeq}
						onTextComplete={onReactPDFGetTextSuccess}
					/>
					:
					null);
		}
		else {
			if (triggerRender) {
				scale = scale+0.001
			}
			
			return (
				<Document
					file={pdfData}
					options={{											
						cMapUrl: '/cmaps/',
						cMapPacked: true
					}}
					
					onLoadSuccess={this.onDocumentLoadSuccess}
					>
					{Array.from(
						new Array(numPages),
						(el, index) => (
						<Page
							key={`page_${index + 1}`}
							pageNumber={index + 1}
							scale={scale}
							onGetTextSuccess={onReactPDFGetTextSuccess}
							onRenderSuccess={()=>{
								// console.log(new Date() + " onRenderSuccess");
								let delayInMilliseconds = 50; //ms
								var target = this;
								// if (this.isNewPdf) {
									setTimeout(function() {
										// target.isNewPdf = false;
										target.setState({ triggerRender:true });
									}, delayInMilliseconds);
								// }
							}}
						/>
						),
					)}
				</Document>);
		}
	}
}