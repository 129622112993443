import React from 'react';
import Modal from 'react-bootstrap/Modal'

import Button from '../../component/button.js';

import '../../../css/input.css'

export default class GeneralModal extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = { show:false };
	}

	handleClose = () => {
		if (this.props.onHide) {
			this.props.onHide();
		}
	}

	render() {
		let {
			title,
			show,
			msgArr,
			closeLabel
		} = {...this.props};

		let msg = [];
		for (let i=0; i<msgArr.length; i++) {
			msg.push(<p key={i}>{msgArr[i]}</p>);
		}

		return (
			<Modal show={show} onHide={this.handleClose} centered >
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{msg}</Modal.Body>
				<Modal.Footer>
					<Button label={closeLabel} onClick={this.handleClose} />
				</Modal.Footer>
			</Modal>
		);
	}
}
