// Street Index
export const RETRIEVE_INFO = "RETRIEVE_INFO";
export const RETRIEVE_INFO_SUCCESS = "RETRIEVE_INFO_SUCCESS";
export const RETRIEVE_INFO_ERROR = "RETRIEVE_INFO_ERROR";

export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const SEARCH_KEYWORD_SUCCESS = "SEARCH_KEYWORD_SUCCESS";
export const SEARCH_KEYWORD_ERROR = "SEARCH_KEYWORD_ERROR";

export const SEARCH_MORE = "SEARCH_MORE";
export const SEARCH_MORE_SUCCESS = "SEARCH_MORE_SUCCESS";
export const SEARCH_MORE_ERROR = "SEARCH_MORE_ERROR";

export const SET_TYPE = "SET_TYPE";
export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_PAGE_SUCCESS = "CHANGE_PAGE_SUCCESS";
export const CHANGE_PAGE_ERROR = "CHANGE_PAGE_ERROR";